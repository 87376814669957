import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import AdvantageBG1 from '../../../static/images/about-us/advantage-bg-1.jpg';
import AdvantageBG2 from '../../../static/images/about-us/advantage-bg-2.jpg';
import AdvantageBG3 from '../../../static/images/about-us/advantage-bg-3.jpg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  .advantage-button {
    margin-bottom: 30px;
    width: 80%;
    height: 120px;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 700;
      color: ${props => props.theme.primeYellowPalette.original};
      font-size: 32px;
      line-height: 36px;
      text-transform: uppercase;
      text-align: center;
    }
    :hover {
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
    }

    @media only screen and (max-width: 768px) {
      height: 80px;
      span {
        font-size: 22px;
        line-height: 28px;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 60px;
      span {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .button-1 {
    background-image: url(${AdvantageBG1});
  }
  .button-2 {
    background-image: url(${AdvantageBG2});
  }
  .button-3 {
    background-image: url(${AdvantageBG3});
  }
`;

function AdvantageButtons() {
  return (
    <Wrapper>
      <Link
        to="/our-expertise/experienced"
        className="advantage-button button-1"
      >
        <span>Explore our services</span>
      </Link>
      <Link to="/leadership" className="advantage-button button-2">
        <span>Get to know our team</span>
      </Link>
      <Link to="/awards-and-recognition" className="advantage-button button-3">
        <span>Our accomplishments and affiliations</span>
      </Link>
    </Wrapper>
  );
}

export default AdvantageButtons;
