import React from 'react';
import styled from 'styled-components';

import Collage from '../../../static/images/about-us/collage.jpg';

const Wrapper = styled.div`
  height: 445px;
  width: 100%;
  background-image: url(${Collage});

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => `${props.theme.primeBluePalette.original}77`};
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .title {
    letter-spacing: 4px;
    color: white;
    border-bottom: 2px solid white;
    z-index: 2;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 46px;
    line-height: 52px;
    font-weight: 600;
    text-align: center;

    :after {
      content: '';
      display: block;
      position: relative;
      top: 19px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 18px 18px 18px 18px;
      margin: 0 auto;
      border-color: white;
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }
  @media only screen and (max-width: 840px) {
    height: 300px;
    .title {
      font-size: 36px;
      line-height: 42px;
    }
  }
  @media only screen and (max-width: 425px) {
    height: 200px;
    .title {
      font-size: 36px;
      line-height: 42px;
    }
  }
`;

function ChallengerOfTraditionSection() {
  return (
    <Wrapper>
      <div className="overlay" />
      <div className="title">THE CHALLENGER OF TRADITION</div>
    </Wrapper>
  );
}

export default ChallengerOfTraditionSection;
