import React, { useState } from 'react';
import styled from 'styled-components';
import ReactAnime from 'react-animejs';
import anime from 'animejs';
import { Row, Col, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlayer from 'react-player/youtube';

import TopOutline from '../../../static/images/outline/top.inline.svg';
import InnerOutline from '../../../static/images/outline/inner.inline.svg';
import avpPreview from '../../../static/images/about-us/avp-preview-a.jpg';

const { Anime } = ReactAnime;

const Wrapper = styled.div`
  position: relative;
  height: 90vh;
  @media only screen and (max-width: 991px) {
    height: unset;
  }
  min-height: 550px;
  background-color: #f1f2f6;
  /* background-color: ${props => props.theme.whitePalette.light}; */
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  z-index: 5;
  padding: 80px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  /* 
  .subtitle {
    color: white;
    letter-spacing: 2px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    margin-bottom: 12px;
  } */
  .header-row {
    min-height: 100%;
  }
  .top-outline {
    position: absolute;
    object-fit: contain;
  }
  .title {
    color: ${props => props.theme.blackPalette.original};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    font-weight: 500;
    margin-bottom: 36px;
    font-size: 60px;
    font-size: 50px;
    .highlight {
      color: ${props => props.theme.primeBluePalette.original};
      font-weight: 700;
      font-family: unset;
    }
  }
  .description {
    color: #2d3436;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    font-size: 22px;
    line-height: 26px;
  }
  .header-col {
    display: flex;
    align-items: flex-end;
  }
  .description-col,
  .avp-col,
  .header-col {
    padding: 12px;
  }
  .description-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .avp-col {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h4 {
      color: #636e72;
      letter-spacing: 4px;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    }
    @media only screen and (max-width: 991px) {
      align-items: flex-start;
    }
  }
  .avp-preview {
    position: relative;
    height: 100%;
  }
  .avp-preview-img {
    /* height: 120px; */
    height: 90%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 0;
    :hover {
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
    }
  }
  .avp-preview-play-button {
    position: absolute;
    cursor: pointer;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto auto auto auto;
    color: white;
    font-size: 5em !important;
  }
  .ant-modal-content {
    height: 70vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .avp-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 1110px) {
    padding: 60px;
    .description {
      width: 85%;
    }
  }
  @media only screen and (max-width: 800px) {
    padding: 40px;
    .title {
      margin-bottom: 24px;
      font-size: 56px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 636px) {
    /* padding: 40px;
    height: 40vh; */
    /* max-height: 450px;
    min-height: 350px; */
    .subtitle {
      margin-bottom: 8px;
      font-size: 14px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 52px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 522px) {
    /* padding: 30px;
    height: 60vh;
    max-height: 550px;
    min-height: 450px; */
    .title {
      margin-bottom: 16px;
      font-size: 48px;
    }
    padding: 30px;
  }
  @media only screen and (max-width: 380px) {
    padding: 15px;
    .title {
      margin-bottom: 20px;
      font-size: 42px;
    }
  }
`;

const StyledAVPModal = styled(Modal)`
  top: 40px !important;
`;

const StyledTopOutline = styled(TopOutline)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledInnerOutline = styled(InnerOutline)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

function PageHeader() {
  const [avpModalVisible, setModalVisible] = useState(false);

  return (
    <Wrapper>
      <Anime
        initial={[
          {
            targets: [
              '#top-outline line',
              '#top-outline path',
              '#top-outline polyline',
            ],
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 1000,
            delay(el, i) {
              return i * 300;
            },
            direction: 'forwards',
          },
          {
            targets: [
              '#inner-outline line',
              '#inner-outline path',
              '#inner-outline polyline',
            ],
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 3000,
            delay(el, i) {
              return i * 1000;
            },
            direction: 'forwards',
          },
        ]}
      >
        <StyledTopOutline id="top-outline" />
        <StyledInnerOutline id="inner-outline" />
      </Anime>
      <Header>
        <Row
          type="inline-flex"
          justify="center"
          className="header-row"
          gutter={[80]}
        >
          {/* <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="header-col"
          >
            
          </Col> */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="description-col"
          >
            <h1 className="title">
              Secure your place in the industry with the{' '}
              <span className="highlight">PRIME Advantage.</span>
            </h1>
            <p className="description">
              <b>PRIME Philippines</b> is a privately-held Filipino-grown real
              estate advisory firm, recognized as the industry&apos;s youngest
              and fastest-growing real estate advisory company in the
              Philippines; trusted by the top 100 developers in the country and
              by several Fortune 500 companies around the world.
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="avp-col"
          >
            {/* <h4>OUR COMPANY AVP</h4> */}
            <div className="avp-preview" onClick={() => setModalVisible(true)}>
              <img
                className="avp-preview-img"
                src={avpPreview}
                alt="AVP video playback"
              />
              <FontAwesomeIcon
                className="avp-preview-play-button"
                fixedWidth
                size="3x"
                icon="play"
              />
            </div>
          </Col>
        </Row>
      </Header>
      <StyledAVPModal
        visible={avpModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width="auto"
        destroyOnClose
        bodyStyle={{ height: '85vh', paddingTop: '50px' }}
      >
        {/* <iframe
          title="Company AVP"
          // width="280"
          // height="157"
          src="https://www.youtube.com/embed/f3BMSOqnywQ"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        ></iframe>
         */}
        <ReactPlayer
          url="https://www.youtube.com/watch?v=f3BMSOqnywQ"
          className="avp-player"
          controls
          // playing={avpModalVisible}
          width="100%"
          height="100%"
        />
      </StyledAVPModal>
    </Wrapper>
  );
}

export default PageHeader;
