import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Row, Col } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeaderSection from '../components/prime-advantage/PageHeaderSection';
// import PRIMEAdvantageSection from '../components/prime-advantage/PRIMEAdvantageSection';
// import BlueHighlightsSection from '../components/prime-advantage/BlueHighlightsSection';
// import LocalStatementSection from '../components/prime-advantage/LocalStatementSection';
// import OurAffiliatesSection from '../components/awards-page/OurAffiliatesComponent';
// import MessageSection from '../components/prime-advantage/MessageSection';
// import CSRStatementSection from '../components/prime-advantage/CSRStatementSection';
import ChallengerOfTraditionSection from '../components/prime-advantage/ChallengerOfTraditionSection';
import CollapsedContent from '../components/prime-advantage/CollapsedContent';
import AdvantageButtons from '../components/prime-advantage/AdvantageButtons';

// import { affiliatesImages } from './awards-and-recognition';

const PageWrapper = styled.div`
  padding-top: 89px;
  overflow: hidden;
  max-width: 1440px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const StyledAffiliatesSection = styled(OurAffiliatesSection)`
//   width: 100%;
//   margin-top: 32px;
// `;

function ThePRIMEAdvantagePage({ location }) {
  return (
    <Layout>
      <SEO
        title="The PRIME Advantage"
        description="We are a privately-held Filipino-grown real estate advisory firm, "
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'real estate services, Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <PageWrapper>
        <PageHeaderSection />
        <ChallengerOfTraditionSection />
        <CollapsedContent />
        <AdvantageButtons />
        {/* <PRIMEAdvantageSection />
        <BlueHighlightsSection />
        <LocalStatementSection />
        <StyledAffiliatesSection affiliatesImages={affiliatesImages} />
        <MessageSection />
        <CSRStatementSection /> */}
      </PageWrapper>
    </Layout>
  );
}

ThePRIMEAdvantagePage.propTypes = {
  location: PropTypes.object,
};

export default ThePRIMEAdvantagePage;
