import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

import EightThreeIcon from '../../../static/images/about-us/advantage-icons/8-3.jpg';
import SixHundredIcon from '../../../static/images/about-us/advantage-icons/600.jpg';
import MapIcon from '../../../static/images/about-us/advantage-icons/map.jpg';
import TransactionsIcon from '../../../static/images/about-us/advantage-icons/70+.jpg';
import ProptechIcon from '../../../static/images/about-us/advantage-icons/proptech.jpg';
import EightIcon from '../../../static/images/about-us/advantage-icons/8.jpg';

const Wrapper = styled.div`
  padding-top: 36px;
  padding-left: 20px;
  padding-right: 20px;

  .section {
    padding: 16px;
    padding-bottom: 0px;
    margin-bottom: 40px;
    border-bottom: 2px solid ${props => props.theme.primeBluePalette.original};
    display: flex;
    flex-direction: column;
    align-items: center;

    :after {
      content: '';
      display: block;
      position: relative;
      top: 19px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 18px 18px 18px 18px;
      margin: 0 auto;
      border-color: ${props => props.theme.primeBluePalette.original};
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }

  .title {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    color: ${props => props.theme.primeYellowPalette.original};
    background-color: ${props => props.theme.primeBluePalette.original};
    padding: 4px 16px;
    margin-bottom: 30px;
    -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
  }
  .content {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  }
  .advantage-icons {
    margin-bottom: 0;
  }
  .advantage-icon-description {
    text-align: center;
    font-weight: 700;
  }
  .read-more-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .read-more-button {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
    height: 60px;
    padding: 8px 16px;
  }
`;

function CollapsedContent() {
  const [showSection2, setShowSection2] = useState(false);
  const [showSection3, setShowSection3] = useState(false);
  return (
    <Wrapper>
      <div className="section">
        <div className="title">
          Challenging traditions through better insights
        </div>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col
            sm={24}
            xs={24}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
            className="content"
          >
            A company built by millennials and young achievers, PRIME
            Philippines is the youngest yet fastest growing real estate
            consultancy firm and has done so through non-traditional means. The
            company has a track record of reinvigorating underperforming
            projects, selling difficult commercial properties, and is the only
            commercial real estate consultancy firm with a dedicated division
            for handling retail projects.
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col
            sm={24}
            xs={24}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
            className="content"
          >
            PRIME Philippines is breaking boundaries and challenging the norms,
            with expertise beyond Central Business Districts and in fully
            understanding and tapping the local market while continuously
            facilitating the entry of different multinational entities.
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={[100, 20]}>
          <Col xs={12} sm={8} md={8} lg={5} xl={5} xxl={5} className="icon-col">
            <img
              alt="8 years, top 3"
              src={EightThreeIcon}
              className="advantage-icons"
            />
            <p className="advantage-icon-description">
              8 years in the industry, top 3 local real estate consultancy firm
            </p>
          </Col>
          <Col xs={12} sm={8} md={8} lg={5} xl={5} xxl={5} className="icon-col">
            <img
              alt="600M+ in USD"
              src={SixHundredIcon}
              className="advantage-icons"
            />
            <p className="advantage-icon-description">
              USD 600M+ in deals transacted across all property sectors
            </p>
          </Col>
        </Row>
      </div>
      <div className="section">
        <div className="title">The authority for emerging markets</div>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col
            sm={24}
            xs={24}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
            className="content"
          >
            With humble beginnings set in Quezon City, PRIME Philippines
            continuously sought to bring out the city&apos;s potential for
            investors and locators from the real estate landscape. This
            aspiration, however, did not stop in Quezon City.
          </Col>
        </Row>
        {!showSection2 ? (
          <div className="read-more-section">
            <Button
              size="large"
              className="read-more-button"
              onClick={() => setShowSection2(true)}
            >
              READ MORE
            </Button>
          </div>
        ) : (
          <>
            <Row type="flex" justify="center" gutter={[20, 20]}>
              <Col
                sm={24}
                xs={24}
                md={20}
                lg={20}
                xl={20}
                xxl={20}
                className="content"
              >
                In addition to one of the country&apos;s most urbanized cities,
                PRIME Philippines has also left its mark in Davao and Cebu, two
                emerging growth centers for real estate related transactions.
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[20, 20]}>
              <Col
                sm={24}
                xs={24}
                md={20}
                lg={20}
                xl={20}
                xxl={20}
                className="content"
              >
                Since its regional inauguration in these cities, PRIME
                Philippines has allowed the influx of potential investors, as
                well as stimulated the interest of businesses in local
                districts, such as Clark, Pampanga. Now an entity present
                nationwide, PRIME Philippines prides itself by not just being a
                real estate consultancy firm, but a city enabler.
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[100, 20]}>
              <Col
                xs={12}
                sm={8}
                md={8}
                lg={5}
                xl={5}
                xxl={5}
                className="icon-col"
              >
                <img
                  alt="Map of the Philippines"
                  src={MapIcon}
                  className="advantage-icons"
                />
                <p className="advantage-icon-description">
                  Regional offices nationwide: in Luzon, Visayas and Mindanao
                </p>
              </Col>
              <Col
                xs={12}
                sm={8}
                md={8}
                lg={5}
                xl={5}
                xxl={5}
                className="icon-col"
              >
                <img
                  alt="70+"
                  src={TransactionsIcon}
                  className="advantage-icons"
                />
                <p className="advantage-icon-description">
                  70+ cities reached for transactions covering over 60% of
                  cities in the Philippines
                </p>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className="section">
        <div className="title">Tech-powered brokerage</div>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col
            sm={24}
            xs={24}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
            className="content"
          >
            Despite being in a highly traditional industry where human
            interaction is still essential, PRIME has walked the talk by
            developing its in-house CRM platform that allows our professionals
            to achieve x efficiency and provide better services for our clients.
          </Col>
        </Row>
        {!showSection3 ? (
          <div className="read-more-section">
            <Button
              size="large"
              className="read-more-button"
              onClick={() => setShowSection3(true)}
            >
              READ MORE
            </Button>
          </div>
        ) : (
          <>
            <Row type="flex" justify="center" gutter={[20, 20]}>
              <Col
                sm={24}
                xs={24}
                md={20}
                lg={20}
                xl={20}
                xxl={20}
                className="content"
              >
                PRIME&apos;s drive for technology is anchored on the Founder and
                CEO&apos;s belief that the future of property is in constantly
                improving software and leveraging on exogenous technology. He is
                an active investor and board of director seat for several
                proptech companies in Asia and looks to improve how real estate
                is done through technology.
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[100, 20]}>
              <Col
                xs={12}
                sm={8}
                md={8}
                lg={5}
                xl={5}
                xxl={5}
                className="icon-col"
              >
                <img
                  alt="Proptech 3.0"
                  src={ProptechIcon}
                  className="advantage-icons"
                />
                <p className="advantage-icon-description">
                  Commitment to improve the real estate industry
                </p>
              </Col>
              <Col
                xs={12}
                sm={8}
                md={8}
                lg={5}
                xl={5}
                xxl={5}
                className="icon-col"
              >
                <img alt="70+" src={EightIcon} className="advantage-icons" />
                <p className="advantage-icon-description">
                  8 years of property technology innovation
                </p>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default CollapsedContent;
